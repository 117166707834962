import { AuthService } from "../../services/auth.service";
import { BackendService } from "../../services/backend.service";
import { JobExpectation } from "../models/jobExpectation.model";
import {
  JobFamilyFE,
  mapJobFamilyToBackEnd,
  mapJobFamilyToFrontEnd,
} from "../models/jobFamily.model";
import { JobFamilyGroup } from "../models/jobFamilyGroup.model";
import {
  JobOrganization,
  mapJobOrganizationToBackEnd,
  mapJobOrganizationToFrontEnd,
} from "../models/jobOrganization.model";
import {
  JobProfileFE,
  mapJobProfileToFrontEnd,
} from "../models/jobProfile.model";

export default class JobService {
  private backend: BackendService;
  private jobOrganizations: JobOrganization[] = [];
  private jobOrgIdMap: Record<string, JobOrganization> = {};
  private jobFamilyGroups: JobFamilyGroup[] = [];
  private jobFamilyGroupIdMap: Record<string, JobFamilyGroup> = {};
  private jobFamilies: JobFamilyFE[] = [];
  private jobFamilyIdMap: Record<string, JobFamilyFE> = {};
  private jobProfiles: JobProfileFE[] = [];
  private jobProfileIdMap: Record<string, JobProfileFE> = {};

  constructor(private authService: AuthService) {
    this.backend = new BackendService(
      process.env.VUE_APP_API_BASE_URL,
      authService
    );
  }

  async getAllJobOrganizations(): Promise<JobOrganization[]> {
    await this.loadAllJobOrganizations();
    return this.jobOrganizations;
  }

  private async loadAllJobOrganizations(): Promise<void> {
    if (this.jobOrganizations.length === 0) {
      const data = await this.backend.request({
        method: "GET",
        path: "/joborganizations/nocache",
      });
      this.jobOrganizations = data.result;
      for (const org of this.jobOrganizations) {
        this.jobOrgIdMap[org.jobOrganizationId] = org;
      }
    }
  }

  async getJobOrganizationById(id: string): Promise<JobOrganization> {
    const data = await this.backend.request({
      method: "GET",
      path: `/joborganizations/${id}`,
    });
    return mapJobOrganizationToFrontEnd(data.result);
  }

  async getJobOrganizationName(id: string): Promise<string> {
    await this.loadAllJobOrganizations();
    return this.jobOrgIdMap[id].name;
  }

  async updateJobOrganization(jobOrg: JobOrganization): Promise<void> {
    const id = jobOrg.jobOrganizationId;
    const jobOrgBE = mapJobOrganizationToBackEnd(jobOrg);
    const data = await this.backend.request({
      method: "PUT",
      path: `/joborganizations/${id}`,
      body: jobOrgBE,
    });
    this.jobOrganizations = []; // invalidate cache
    return data.result;
  }

  proceedJobOrganizationSubmission(jobOrg: JobOrganization): boolean {
    if (jobOrg.expectations) {
      const categoryCount = this.getCategoryCount(jobOrg.expectations);
      if (categoryCount !== 0 && categoryCount !== jobOrg.expectations.length) {
        const proceed = window.confirm(
          this.generateExpectationCategoryWarning(
            categoryCount,
            jobOrg.expectations.length - categoryCount
          )
        );
        return proceed;
      }
    }
    return true;
  }

  async getAllJobFamilyGroups(): Promise<JobFamilyGroup[]> {
    await this.loadAllJobFamilyGroups();
    return this.jobFamilyGroups;
  }

  async getJobFamilyGroupsByOrganization(
    jobOrganizationId: string
  ): Promise<JobFamilyGroup[]> {
    await this.loadAllJobFamilyGroups();
    const groups = [];
    for (const grp of this.jobFamilyGroups) {
      if (grp.jobOrganizationId === jobOrganizationId) {
        groups.push(grp);
      }
    }
    return groups;
  }

  private async loadAllJobFamilyGroups(): Promise<void> {
    if (this.jobFamilyGroups.length === 0) {
      await this.loadAllJobOrganizations();
      const data = await this.backend.request({
        method: "GET",
        path: "/jobfamilygroups/nocache",
      });
      this.jobFamilyGroups = data.result;
      for (const grp of this.jobFamilyGroups) {
        this.jobFamilyGroupIdMap[grp.jobFamilyGroupId] = grp;
        grp.jobOrganizationName = this.jobOrgIdMap[grp.jobOrganizationId]?.name;
      }
    }
  }

  async getJobFamilyGroupById(id: string): Promise<JobFamilyGroup> {
    const data = await this.backend.request({
      method: "GET",
      path: `/jobfamilygroups/${id}`,
    });
    data.result.jobOrganizationName = await this.getJobOrganizationName(
      data.result.jobOrganizationId
    );
    return data.result;
  }

  async getJobFamilyGroupName(id: string): Promise<string> {
    await this.loadAllJobFamilyGroups();
    return this.jobFamilyGroupIdMap[id]?.name;
  }

  async updateJobFamilyGroup(jobFamGrp: JobFamilyGroup): Promise<void> {
    const id = jobFamGrp.jobFamilyGroupId;
    const data = await this.backend.request({
      method: "PUT",
      path: `/jobfamilygroups/${id}`,
      body: jobFamGrp,
    });
    this.jobFamilyGroups = []; // invalidate cache
    return data.result;
  }

  async getJobFamiliesByFamilyGroup(
    jobFamilyGroupId: string
  ): Promise<JobFamilyFE[]> {
    await this.loadAllJobFamilies();
    const families = [];
    for (const family of this.jobFamilies) {
      if (family.jobFamilyGroupId === jobFamilyGroupId) {
        families.push(family);
      }
    }
    return families;
  }

  private async loadAllJobFamilies(): Promise<void> {
    if (this.jobFamilies.length === 0) {
      await this.loadAllJobOrganizations();
      await this.loadAllJobFamilyGroups();
      const data = await this.backend.request({
        method: "GET",
        path: "/jobfamilies/nocache",
      });
      this.jobFamilies = data.result;
      for (const family of this.jobFamilies) {
        this.jobFamilyIdMap[family.jobFamilyId] = family;
        family.jobFamilyGroupName =
          this.jobFamilyGroupIdMap[family.jobFamilyGroupId]?.name;
      }
    }
  }

  async getJobFamilyById(id: string): Promise<JobFamilyFE> {
    const data = await this.backend.request({
      method: "GET",
      path: `/jobfamilies/${id}`,
    });
    const jobFamilyGroupName = await this.getJobFamilyGroupName(
      data.result.jobFamilyGroupId
    );
    const jobFamily = mapJobFamilyToFrontEnd(data.result, jobFamilyGroupName);
    return jobFamily;
  }

  async getJobFamilyName(id: string): Promise<string> {
    await this.loadAllJobFamilies();
    return this.jobFamilyIdMap[id].name;
  }

  proceedJobFamilySubmission(jobFamily: JobFamilyFE): boolean {
    if (jobFamily.expectations) {
      const categoryCount = this.getCategoryCount(jobFamily.expectations);
      if (
        categoryCount !== 0 &&
        categoryCount !== jobFamily.expectations.length
      ) {
        const proceed = window.confirm(
          this.generateExpectationCategoryWarning(
            categoryCount,
            jobFamily.expectations.length - categoryCount
          )
        );
        return proceed;
      }
    }
    return true;
  }

  private getCategoryCount(expectations: JobExpectation[]): number {
    let categoryCount = 0;
    for (const exp of expectations) {
      if (exp.category) {
        categoryCount++;
      }
    }
    return categoryCount;
  }

  private generateExpectationCategoryWarning(
    categoryCount: number,
    expectationCount: number
  ): string {
    return (
      `You have ${categoryCount} expectation(s) with categories and ${expectationCount} ` +
      "expectation(s) without categories. If this is intentional, please continue, " +
      "otherwise select 'Cancel' and make your adjustments."
    );
  }

  async updateJobFamily(jobFamily: JobFamilyFE): Promise<void> {
    const id = jobFamily.jobFamilyId;
    const jobFamilyBE = mapJobFamilyToBackEnd(jobFamily);
    const data = await this.backend.request({
      method: "PUT",
      path: `/jobfamilies/${id}`,
      body: jobFamilyBE,
    });
    this.jobFamilies = []; // invalidate cache
    return data.result;
  }

  async getJobProfilesByJobFamily(
    jobFamilyId: string
  ): Promise<JobProfileFE[]> {
    await this.loadAllJobProfiles();
    const profiles = [];
    for (const profile of this.jobProfiles) {
      if (profile.jobFamilyId === jobFamilyId) {
        profiles.push(profile);
      }
    }
    return profiles;
  }

  private async loadAllJobProfiles(): Promise<void> {
    if (this.jobProfiles.length === 0) {
      await this.loadAllJobOrganizations();
      await this.loadAllJobFamilyGroups();
      await this.loadAllJobFamilies();
      const data = await this.backend.request({
        method: "GET",
        path: "/jobprofiles/nocache",
      });
      this.jobProfiles = data.result;
      for (const profile of this.jobProfiles) {
        this.jobProfileIdMap[profile.jobProfileId] = profile;
        profile.jobFamilyName = this.jobFamilyIdMap[profile.jobFamilyId].name;
      }
    }
  }

  async getJobProfileById(id: string): Promise<JobProfileFE> {
    const data = await this.backend.request({
      method: "GET",
      path: `/jobprofiles/${id}`,
    });
    const jobFamilyName = await this.getJobFamilyName(data.result.jobFamilyId);

    const jobProfile = mapJobProfileToFrontEnd(
      data.result,
      jobFamilyName,
      data.result.jobLevel
    );
    return jobProfile;
  }

  async getJobProfileName(id: string): Promise<string> {
    await this.loadAllJobProfiles();
    return this.jobProfileIdMap[id].name;
  }

  async updateJobProfile(jobProfile: JobProfileFE): Promise<void> {
    const id = jobProfile.jobProfileId;
    const data = await this.backend.request({
      method: "PUT",
      path: `/jobprofiles/${id}`,
      body: jobProfile,
    });
    this.jobProfiles = []; // invalidate cache
    return data.result;
  }
}
